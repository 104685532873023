// Import required Bootstrap parts
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// Import Bootstrap's grid
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";

// Import Bootstrap's utilities
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/utilities/api";

@import 'ngx-toastr/toastr';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

//Fix for expanding box to full width even when the content inside is smaller
.box-wrapper > router-outlet + * {
  display: block;
  width: 100%;
}

.box {
  display: flex;
  flex-direction: column;

  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;

  &__title {
    text-align: center;
    margin-top: 0;
    font-weight: normal;
  }

  &__content {
    display: flex;
    flex-direction: column;

    padding-top: 5px;

    > * {
      margin: 0;
    }

    > * {
      text-align: center;
    }
  }

  &__actions {
    display: flex;
    gap: 10px;
    margin-top: auto;
    padding-top: 16px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    button {
      flex-grow: 1;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}

mat-spinner {
  align-self: center;
}